<div class="modal-header">
      <h4 class="modal-title">Información Financiera</h4>
      <button insert type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
      <div>

            @if ( !isLoading && !isString && scoreTable.length > 0 && obligacionesTotales.length > 0 ) {
                  <app-credits-table-trans-union [scoreTable]="scoreTable" [obligacionesT]="obligacionesTotales" />
            }

            @if ( isLoading ) {
            <div class="d-flex justify-content-center align-items-center">
                  <app-credits-loader-trans-union />
            </div>
            }

            @if ( !isLoading && isString ) {
            <h5>No hay información TransUnion <i class="fa-solid fa-circle-exclamation"></i> </h5>
            }

            @if ( !isLoading && isNullRue ) {
            <br>
            <h5>No hay información Rues <i class="fa-solid fa-circle-exclamation"></i> </h5>
            }

            @if ( !isLoading && !isNullRue ) {
            <app-credits-table-rues [infoRues]="ruesTable" />
            }

      </div>
</div>
<div class="modal-footer">
      <button insert type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Cerrar</button>
</div>
