import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersService } from '../../../services/customers.service';
import { CommonModule } from '@angular/common';
import { CreditsTableRuesComponent } from '../../credits-table-rues/credits-table-rues.component';
import { CreditsTableTransUnionComponent } from '../../credits-table-trans-union/credits-table-trans-union.component';
import { CreditsLoaderTransUnionComponent } from '../../credits-loader-trans-union/credits-loader-trans-union.component';

@Component({
  selector: 'app-customers-info-trans-union',
  standalone: true,
  imports: [
      CommonModule,

      CreditsTableRuesComponent,
      CreditsTableTransUnionComponent,
      CreditsLoaderTransUnionComponent
  ],
  templateUrl: './customers-info-trans-union.component.html',
  styleUrl: './customers-info-trans-union.component.scss'
})
export class CustomersInfoTransUnionComponent {
      @Input() customerId!: number;

      public obligacionesTotales: any[] = [];
      public scoreTable: any[] = [];
      public ruesTable: any[] = [];
      public isString: boolean = false;
      public isLoading: boolean = false;
      public isNullRue: boolean = false;

      /**
       * * Inicializa la información de la vista.
       */
      ngOnInit() {
            if( this.customerId ) {
                  this.getInfoCustomerFinantial();
            }
      }

      constructor(public activeModal: NgbActiveModal,
            private customerService: CustomersService,
      ) { }

      /**
       * * Obtiene la información financiera del cliente.
       */
      public getInfoCustomerFinantial() {
            this.isLoading = true;
            this.customerService.getInfoTransunionAndRues(this.customerId).subscribe((response) => {
                  this.handleRuesAndTransunionInfo(response.data.info_transunion, 'transunion');
                  this.handleRuesAndTransunionInfo(response.data.info_rues, 'rues');
                  this.isLoading = false;
            },(error) => {
                  console.error(error);
                  this.isLoading = false;
            });
      }

      /**
       * * Actualiza la información de la vista.
       */
      private extractInformationTransunion(jsonObject: any) {
            // Información Score
            this.scoreTable = [{
                  score: jsonObject.Tercero.Score?.Puntaje,
                  grupo: jsonObject.Tercero.Score?.Politica,
                  negativeReasonCode: jsonObject.Tercero.Score?.IndicadorDefault,
                  positiveReasonCode: jsonObject.Tercero.Score?.CodigoScore || '',
                  subpoblacion: jsonObject.Tercero.Score?.SubPoblacion?.join(', ') || ''
            }];

            // Resumen Endeudamiento
            const resumenPrincipal = jsonObject.Tercero?.Consolidado?.ResumenPrincipal?.Registro;
            this.obligacionesTotales = resumenPrincipal?.map((obligacion: any) => ({
                  tipo: obligacion.PaqueteInformacion,
                  cantidad: obligacion.NumeroObligaciones,
                  saldoTotal: obligacion.TotalSaldo,
                  cuota: obligacion.CuotaObligacionesDia
            }));
      }

      /**
       * * Extrae la información de RUES.
      */
      private extractInformationRues(jsonObject: any) {
            this.ruesTable = [{
                  fechaMatricula: jsonObject.fecha_matricula,
                  estadoMatricula: jsonObject.estado_matricula,
                  fechaRenovacion: jsonObject.fecha_renovacion,
                  ultimoAnoRenovacion: jsonObject.ultimo_ano_renovado,
            }]
      }


      /**
       * * Maneja la información de RUES y Transunion.
       * @param finantialInfo Información de RUES o Transunion.
       * @param type Tipo de información.
       */
      private handleRuesAndTransunionInfo(finantialInfo: any, type: 'rues' | 'transunion') {
            const jsonObjectRues = JSON.parse(finantialInfo);
            if (finantialInfo === null || jsonObjectRues.length === 0) {
                  if(type === 'rues') {
                        this.isNullRue = true;
                  } else {
                        this.isString = true;
                  }
            } else {
                  if(type === 'rues') {
                        this.extractInformationRues(jsonObjectRues);
                  } else {
                        this.extractInformationTransunion(jsonObjectRues);
                  }
            }
      }
}
