import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-credits-table-trans-union',
  standalone: true,
  imports: [
      CommonModule
  ],
  templateUrl: './credits-table-trans-union.component.html',
  styleUrl: './credits-table-trans-union.component.scss'
})
export class CreditsTableTransUnionComponent {
      @Input() public obligacionesT: any[] = [];
      @Input() public scoreTable: any[] = [];
}
