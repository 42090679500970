import { Component } from '@angular/core';

@Component({
  selector: 'app-credits-loader-trans-union',
  standalone: true,
  imports: [],
  templateUrl: './credits-loader-trans-union.component.html',
  styleUrl: './credits-loader-trans-union.component.scss'
})
export class CreditsLoaderTransUnionComponent {

}
